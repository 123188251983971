import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
//import { User } from '../models/user.model';
import * as moment from 'moment';
import { Observable, throwError, BehaviorSubject } from 'rxjs';

import { tap, map, retry, catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { User } from './models/user.model';
import { Company } from './models/company.model';
//import { User } from './models/user.model';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  add(arg0: { severity: string; summary: string; detail: string }) {
    throw new Error('Method not implemented.');
  }

  getUser(id: any, page: any) {
    let url = `${this.baseUrl}/user`;
    var data = {};

    this.user.subscribe(res => {
      data = {
        token: res.token,
      };
    });

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  baseUrl = environment.apiserver;
  userSession: User;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private httpClient: HttpClient) {
    //getting current user
    this.user.subscribe(data => {
      this.userSession = data;
    });
  }

  private session = new BehaviorSubject(new User());
  user: Observable<User> = this.session.asObservable();

  updatedDataSelection(data: User) {
    this.session.next(data);
  }

  updateUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/update`;

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  getCompany(id?: number): Observable<any[]> {
    let url = `${this.baseUrl}/company`;

    if (id > 0) {
      url = `${this.baseUrl}/company/` + id;
    }

    return this.httpClient.get<any[]>(url).pipe(retry(1), catchError(this.handleError));
  }

  getCompanyAdminUsers(id): Observable<User> {
    let url = `${this.baseUrl}/companyAdminUsers/` + id;

    return this.httpClient
      .get<User>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  addCompany(data: any): Observable<any> {
    let url = `${this.baseUrl}/savecompany`;

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  saveNotification(data: any): Observable<any> {
    let url = `${this.baseUrl}/natificationsave`;

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  getNotification(id?: number, page?: number) {
    let url = `${this.baseUrl}/natifications?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/natifications/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/natifications?page=` + page;
    }

    return this.httpClient.get<any[]>(url).pipe(retry(1), catchError(this.handleError));
  }

  getlevelOfDivisions(id?: number): Observable<any> {
    let url = `${this.baseUrl}/unlimitdiv`;

    if (id > 0) {
      url = `${this.baseUrl}/unlimitdiv/` + id;
    }

    return this.httpClient.get<any[]>(url).pipe(retry(1), catchError(this.handleError));
  }

  getWarlevelOfDivisions(id?: number): Observable<any> {
    let url = `${this.baseUrl}/warlimitdiv`;

    if (id > 0) {
      url = `${this.baseUrl}/warlimitdiv/` + id;
    }

    return this.httpClient.get<any[]>(url).pipe(retry(1), catchError(this.handleError));}

  listAllcompanies(): Observable<Company[]> {
    let url = `${this.baseUrl}/company/listall`;

    return this.httpClient.get<Company[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    }
  

  getMemberByUser(id?: number, page?: number): Observable<User> {
    let url = `${this.baseUrl}/user?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/user/` + id;
    }

    return this.httpClient.get<User>(url).pipe(retry(1), catchError(this.handleError));
  }

  validateUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/login`;

    return this.httpClient.post(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }
  sendPasswordResetLink(data: any) {
    let url = `${this.baseUrl}/sendPasswordResetLink`;
    debugger;

    return this.httpClient.post(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  public isLoggedIn() {
    var yes = moment().isBefore(this.getExpiration());
    return yes;
  }

  getExpiration() {
    // this.user.subscribe(data =>{
    //   const expiration = data.tokenexpire;
    //   //const expiresAt = JSON.parse(expiration);
    //   return (expiration);
    // });

    const expiration = this.userSession.tokenexpire;
    return expiration;
  }

  getLoggedUser(): Observable<boolean> {
    let url = `${this.baseUrl}/loggeduser`;
    var data = {};

    this.user.subscribe(res => {
      data = {
        token: res.token,
      };
    });

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  endSession(): Observable<boolean> {
    let url = `${this.baseUrl}/logout`;

    var data = {};

    this.user.subscribe(res => {
      data = {
        token: res.token,
      };
    });

    return this.httpClient.post<any>(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  saveUser(data: any): Observable<User> {
    let url = `${this.baseUrl}/register`;
    if (data['id'] != 0) {
      url = `${this.baseUrl}/update/` + data['id'];
    }

    return this.httpClient.post<User>(url, JSON.stringify(data), this.httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  saveUserPicture(data) {
    let url = `${this.baseUrl}` + '/user/profilepic';

    return this.httpClient.post(url, data).pipe(retry(0), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
