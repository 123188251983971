import * as moment from 'moment';

export class User{
    public id : number
    public name : string
    public email : string
    public password : string
    public image : string
    public role: string
    public contact : string
    public token : string
    public level : number
    public company_id : number
    public tokenexpire : moment.Moment
}