import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  providers: [MessageService]
})
export class RegisterComponent {

  
  name : string;
  email : string;
  password : string;
  repeatpassword : string;
  msgs : any = [];

  constructor(private messageService: MessageService) {}

  addUser(event){
    this.msgs = [];
    if(this.password != this.repeatpassword){
      this.msgs.push({severity:'error', summary:'Error Message', detail:'Passwords are not matching!'});
      return;
    }
    
  }

}
