interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-line-chart',
  },
  {
    title: true,
    name: 'Main',
  },
  {
    name: 'Location Tree View',
    url: '/location-treeview',
    icon: 'fa fa-sort-amount-asc',
  },
  {
    name: 'Overview',
    url: '/overview',
    icon: 'fa fa-eye',
  },
  // {
  //   name :'Alerts',
  //   url : '/alerts',
  //   icon: 'fa fa-exclamation-triangle',
  // },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'fa fa-sticky-note',
  },
  {
    name: 'Manage Device',
    url: '/update',
    icon: 'fa fa-plug',
  },
  // {
  //   name :'User',
  //   url : '/user',
  //   icon: 'fa fa-user',
  // },
  // {
  //   name :'Unit Limit',
  //   url : '/unitlimit',
  //   icon: 'fa fa-exclamation-triangle',
  // },
  {
    name: 'Budget Limit',
    url: '/budgetlimit',
    icon: 'fa fa-exclamation-triangle',
  },
  // {
  //   name :'Permission',
  //   url : '/permissions',
  //   icon: 'fa fa-user',
  // },
  // {
  //   name :'Register User',
  //   url : '/registers',
  //   icon: 'fa fa-user',
  // },
  // {
  //   title: true,
  //   name: 'Extra',
  // },
  // {
  //   name :'Settings',
  //   url : '/customer',
  //   icon: 'cui-cog'
  // },
  {
    name: "Super Admin",
    url: "/superadmin",
    icon: "pi pi-users",
    children: [
      {
        name: "Manage Features",
        url: "/superadmin/features-new",
        icon: "pi pi-calendar-plus",
      },
      {
        name: "Manage Companies",
        url: "/superadmin/companylist",
        icon: "pi pi-calendar-plus",
      },
      {
        name: "New Company",
        url: "/superadmin/company-edit",
        icon: "pi pi-plus-circle",
      },
    ]
  }
];
