import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
// import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
// import { LocationMainComponent } from './modules/base/location-main/location-main.component';

export const routes: Routes = [
 
  
  {
    path :'',
    loadChildren : () => import('./modules/base/base.module').then(m => m.BaseModule)
   },
  {
    path :'login',
    loadChildren : () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
        loadChildren : () => import('./modules/register/register.module').then(m => m.RegisterModule)
      
  } ,
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   data: {
  //     title: 'Register Page'
  //   }
  // },  
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
