import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService} from 'primeng/api';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
//import { LoginComponent } from './views/login/login.component';
//import { RegisterComponent } from './views/register/register.component';

//crm

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginModule } from './modules/login/login.module';
import { RegisterModule } from './views/register/register.module';
import { PermissionsComponent } from './modules/base/permissions/permissions.component';
import { AuthInterceptor } from './shared/services/core/AuthInterceptor';

// import { BuildaddComponent } from './modules/base/buiding-main/buildadd/buildadd.component';

//import { RegisterComponent } from './modules/register/register.component';
// import { LoceditComponent } from './modules/base/locedit/locedit.component';
// import { LoceditModule } from './modules/base/locedit/locedit.module';
// import { LocaddModule } from './modules/base/locadd/locadd.module';
// import { LocaddComponent } from './modules/base/locadd/locadd.component';
// import { DiveditModule } from './modules/base/divedit/divedit.module';
// import { DiveditComponent } from  './modules/base/divedit/divedit.component';
// import { DevaddComponent } from './modules/base/devadd/devadd.component';
// import { DevaddModule } from './modules/base/devadd/devadd.module';
// import { DevsettingComponent } from './modules/base/devsetting/devsetting.component';
// import { DevsettingModule } from './modules/base/devsetting/devsetting.module';
// import { LocationComponent } from './modules/base/location/location.component';

// import { LocationMainComponent } from './modules/base/location-main/location-main.component';
// import { Location } from './location.model/location.model.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, 
    ReactiveFormsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    LoginModule,
    RegisterModule,
   
    // BuildaddComponent
    
    // LoceditModule,
    // LocaddModule,
    // DiveditModule,
    // DevaddModule,
    // DevsettingModule,
    
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    
    // LocationComponent,
    
    
    // Location.ModelComponent,
    //RegisterComponent,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [ AppComponent ],
  // entryComponents: [DevsettingComponent]
  
})
export class AppModule { }
