import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
//import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
//import { User } from 'src/app/shared/models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { enableProdMode } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { User } from '../../shared/services/models/user.model';
import { AuthService } from '../../shared/services/auth.service';
import { DatePipe, formatDate } from '@angular/common';
import { NotificationService } from '../../shared/services/notification.service';
import { Division } from '../../shared/services/models/division.model';
import { Notification } from '../../shared/services/models/notification.model';

enableProdMode();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService, DatePipe],
})
export class LoginComponent implements OnInit {
  newForm: FormGroup;
  username: string;
  password: string;
  isWaiting: boolean = false;
  isread: boolean = false;
  userSession: User;

  msgs: Message[] = [];
  submitted: boolean;
  meta: any;
  userList: User[] = [];
  notification: any;
  notfcn: any;
  notfcns: any;
  data: any;
  devision: any;
  wardivisions: any;
  myDate = new Date();
  notificationForm: FormGroup;
  divisions: any;
  notifObj : Notification[] = [];
  Ntfctn: Object[] = [];
  empList: Array<Division> = [];

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private _router: Router,
    private fb: FormBuilder,
    private NotificationService: NotificationService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.authService.getlevelOfDivisions().subscribe((res) => {  
      this.divisions = res;
      
    });

    this.authService.getWarlevelOfDivisions().subscribe((res) => {  
      this.wardivisions = res;
      console.log(this.wardivisions);
    });
   
    
    this.isWaiting = false;
    this.msgs = [];
    this.buildForm();
    this.getUser();
    

    //getting current user
    this.authService.user.subscribe(data => {
      this.userSession = data;
    });

   
  } 

  validateUser() {
    //this.isWaiting = true;

    var credentials = {
      email: this.newForm.get('email').value,
      password: this.newForm.get('password').value,
    };

    this.authService.validateUser(credentials).subscribe(
      res => {
        console.log(res);
        this.isWaiting = false;

        this.authService.updatedDataSelection({
          id:res["id"], 
          name:res["name"], 
          email:res["email"], 
          password:"", 
          image:res["image"], 
          role:res["role"],
          contact:res["contact"],
          level:res["level"],
          company_id:res["company_id"], 
          token :res["access_token"], 
          tokenexpire: moment().add(JSON.parse(res["expires_in"]), 'second') 
        });
        this.isWaiting = false;
     
        this.saveNotification();
      
        this._router.navigate(['/dashboard']);
      },
      err => {
        this.isWaiting = false;
        this.msgs = [];
        this.messageService.add({ severity: 'error', detail: 'Sorry, username or password is incorrect!' });
      },
    );
  }


  
  saveNotification() {

    // this.notifObj = [] =[];
  
   for(let value of this.divisions){ 
  
    this.notfcn = this.NotificationService.getOverbudgetNotification(value.division.division_name);
    this.notfcns = this.notfcn.toString();
    
   var notifElement : Notification = {
      id: null,
      company_id: null,
      division_id : value.division_id,
      user_id : this.userSession.id,
      notification : this.notfcns,
      read: false,
      notify_datetime: formatDate(new Date(), 'yyyy/MM/dd hh:mm:ss', 'en'),
      read_datetime: formatDate(new Date(), 'yyyy/MM/dd hh:mm:ss', 'en')
    };

    this.notifObj.push(notifElement);

  }

  
  this.authService.saveNotification(this.notifObj).subscribe(() => {});
  console.log(this.notifObj); 
  }

  // getDivisions() {
    
  //   this.authService.getlevelOfDivisions().subscribe((res) => { 
  //   this.divisions = res;
 
  // });
  

  // }

  
  buildForm() {
    //form group for register new custormer
    this.newForm = this.fb.group({
      id: [''],
      name: [''],
      email: ['', Validators.required],
      password: ['', Validators.required],
      image: [''],
      role: [''],
      contact: ['0'],
      status: ['1'],
    });
  }
  Submit(value: string) {
    if (this.newForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill required fields!' });
      return true;
    }

    this.authService.validateUser(this.newForm.value).subscribe(res => {
      var tempId = res['data']['id'];
      this.getUser();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'successfully!' });

      this._router.navigate(['/base/' + res['data']['id']]);
    });
    this.submitted = true;
  }
  getUser(id?, page?) {
    // this.authService.getUser(id , page).subscribe(res =>{
    //   this.userList = res['data'];
    //   this.meta = res['meta'];
    // });
  }
}
