import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';


@NgModule({
  declarations: [RegisterComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    MessagesModule
  ]
})
export class RegisterModule { }
