import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private httpClient: HttpClient) {}

  getOverbudgetNotification(division: any) {
   
      return  division + ' is running out of budget!';
    
  }

  getWarnningNotification(division: any) {
   
    return  + division + ' is warnning level!';
  
}
}
